import axios from "axios";

import { mwURL_Global } from './global'

export function like(messageid, typeofcontent, email) {
   
    axios
      .post(
        mwURL_Global + "/azureblob/v1/like",
        {
            "email": email,
            "messageId":messageid,
            "type": typeofcontent
        },
        { "Content-Type": "application/json" }
      )
      .then((response) => {
       console.log("like")
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });


    }

    export function disLike(messageid, typeofcontent, email) {
    
        axios
          .post(
            mwURL_Global + "/azureblob/v1/dislike",
            {
                "email": email,
                "messageId":messageid,
                "type": typeofcontent
            },
            { "Content-Type": "application/json" }
          )
          .then((response) => {
           console.log("dislike")
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error:", error);
          });
    
        }
    

    export function comment(messageid, typeofcontent, email, comment) {
    
        axios
          .post(
            mwURL_Global + "/azureblob/v1/addcomment",
            {
                "email": email,
                "messageId":messageid,
                "type": typeofcontent,
                "message":comment
            },
            { "Content-Type": "application/json" }
          )
          .then((response) => {
           console.log("comment",comment)
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error:", error);
          });
    
        }
