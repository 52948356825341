import React, { useState, useEffect } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import axios from "axios";
import {  mwURL_Global, pluginURL_Global} from "../../global";
// import { MdOutlineChatBubbleOutline } from "react-icons/md";
// menue icons
// import { CgProfile } from "react-icons/cg";
// import { BsTags } from "react-icons/bs";
// import { CiTimer } from "react-icons/ci";
// import { IoBulbOutline } from "react-icons/io5";
// import { PiVideoCameraBold } from "react-icons/pi";
// import { FaSignOutAlt } from "react-icons/fa";
// import { MdWorkspacePremium } from "react-icons/md";
// import { BiMessageDots } from "react-icons/bi";

export default function Header({ headerChangeScreen }) {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isAutoDraft, setisAutoDraft] = useState(false);
  const [isaiAssistant, setaiAssistant] = useState(false);
  const [IsInfoFetched, setIsInfoFetched] = useState(false);
  const [isAutoDraftOn, setisAutoDraftOn] = useState(false);

  useEffect(() => {
    
    GetAIAssistant_AutoDraftStatus();

  }, [IsInfoFetched]);




  const GetAIAssistant_AutoDraftStatus = () => {

    axios
      .get(mwURL_Global + "/v1/get/autodraft", {
        params: {
          email: Office.context.mailbox.userProfile.emailAddress,
        },
      })
      .then((response) => {
        ///console.log("Response:", response);
        // Handle the response
        setIsInfoFetched(true);
        setisAutoDraft(response.data.result.isAutoDraft)
        setaiAssistant(response.data.result.AIAssistant)
        setisAutoDraftOn(response.data.result.route_auto_draft)

        console.log("response.data", response.data);

      })
      .catch((error) => {

        console.error("Error:", error);
      });
  };


  const aiAssistantUI = () => {
    let dialog;
    Office.context.ui.displayDialogAsync(
      mwURL_Global+"/v1/getaiassistantlink?email=" + Office.context.mailbox.userProfile.emailAddress,
      { height: 80, width: 50, displayInIframe: false },
      function (asyncResult) {
        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      }
    );
    function processMessage(arg) {
      const messageFromDialog = JSON.parse(arg.message);
      showUserName(messageFromDialog.name);
    }

  };

  

  const AutoDraftTurnOFF = () => {

    axios
      .put(
        mwURL_Global + "/v1/off/autodraft?email=" + Office.context.mailbox.userProfile.emailAddress,
        {},
        { "Content-Type": "multipart/form-data" }
      )
      .then((response) => {

        localStorage.setItem("isAutoDraftOn", false)
        //setisAutoDraftOn(false);
        setIsInfoFetched(false);
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });

  };

  const AutoDraftTurnON = () => {

    let dialog;
    let URL = mwURL_Global + "/v1/on/autodraft?email=" + Office.context.mailbox.userProfile.emailAddress + "&service=AutoDraft"
    Office.context.ui.displayDialogAsync(
      URL,
      { height: 80, width: 50, displayInIframe: false },
      function (asyncResult) {
        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg) => {
          console.log("DialogMessageReceived");
          console.log("messageFromDialog", arg.message)
          if(arg.message=="autodrafton"){
            dialog.close();
            GetAIAssistant_AutoDraftStatus();
          }
          
          
        });
       
      }
    );
    
    setIsInfoFetched(false);
  };


  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  return (
    <div className="header">
      <div className="logo-container">
        {/* <img src={logo} alt="Logo" className="logo" />
        <span className="logo-text">SeAMate</span> */}
      </div>

      <div className="icons-container">

        <IoSettingsOutline onClick={() => { headerChangeScreen("Profile"); }} className="icon cursor-pointer" />
        {/* <IoSettingsOutline onClick={toggleSettings} className="icon cursor-pointer" /> */}
        {isSettingsOpen && (
          <div className="settings-list">
            <div
              className="list-item"
              onClick={() => {
                headerChangeScreen("Profile");
              }}
            >
              {/* <CgProfile className="list-icon" /> */}
              <svg
                className="list-icon"
                id="user-avatar-filled-alt"
                xmlns="http://www.w3.org/2000/svg"
                width="13.996"
                height="13.996"
                viewBox="0 0 13.996 13.996"
              >
                <path
                  id="Path_44"
                  data-name="Path 44"
                  d="M19,12a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,19,12Z"
                  transform="translate(-12.001 -9.001)"
                  fill="#3d3d3d"
                />
                <path
                  id="Path_45"
                  data-name="Path 45"
                  d="M10,3a7,7,0,1,0,7,7,7,7,0,0,0-7-7Zm3.995,11.459A2.5,2.5,0,0,0,11.5,12h-3A2.5,2.5,0,0,0,6,14.459a6,6,0,1,1,7.99,0Z"
                  transform="translate(-3 -3)"
                  fill="#3d3d3d"
                />
              </svg>
              <span className="list-text">My Profile </span>
            </div>
            {/* <div className="list-item">
              
              <svg
                className="list-icon"
                id="Group_69"
                data-name="Group 69"
                xmlns="http://www.w3.org/2000/svg"
                width="12.25"
                height="12.25"
                viewBox="0 0 12.25 12.25"
              >
                <rect id="Rectangle_34" data-name="Rectangle 34" width="12.25" height="12.25" fill="none" />
                <g id="tag-group" transform="translate(1)">
                  <circle
                    id="Ellipse_8"
                    data-name="Ellipse 8"
                    cx="0.875"
                    cy="0.875"
                    r="0.875"
                    transform="translate(1.644 4.442)"
                    fill="#3d3d3d"
                  />
                  <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M11.256,21.5a.441.441,0,0,1-.311-.125L6.258,16.75A.858.858,0,0,1,6,16.141V12.864A.87.87,0,0,1,6.876,12H10.2a.882.882,0,0,1,.618.255l4.691,4.62a.428.428,0,0,1,0,.613l-3.942,3.886a.441.441,0,0,1-.311.125ZM7.022,13.048V16L11.256,20.2l3.148-3.02-4.2-4.134Z"
                    transform="translate(-6 -9.25)"
                    fill="#3d3d3d"
                  />
                  <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M18.509,7.875l-4.691-4.62A.882.882,0,0,0,13.2,3H9.876A.87.87,0,0,0,9,3.864v.864h1.094v-.69H13.2l4.187,4.144-.45.5.7.668.876-.864a.428.428,0,0,0,0-.613Z"
                    transform="translate(-8.136 -3)"
                    fill="#3d3d3d"
                  />
                </g>
              </svg>
              <span className="list-text">Priority Tags</span>
            </div>
            <div className="list-item">
              <CiTimer className="list-icon" />
              <span className="list-text">Time Mapping</span>
            </div>
             <div className="list-item">
              <MdWorkspacePremium className="list-icon" />
              <span className="list-text">Subscription</span>
            </div>
            <hr />
            <div className="list-item">
             
              <svg
                className="list-icon"
                id="Group_81"
                data-name="Group 81"
                xmlns="http://www.w3.org/2000/svg"
                width="12.25"
                height="12.25"
                viewBox="0 0 12.25 12.25"
              >
                <rect id="Rectangle_40" data-name="Rectangle 40" width="12.25" height="12.25" fill="none" />
                <g id="communitysmall">
                  <path
                    id="Path_50"
                    data-name="Path 50"
                    d="M6.125,12.25a6.009,6.009,0,0,1-2.381-.484,5.993,5.993,0,0,1-3.26-3.26,6.091,6.091,0,0,1,0-4.761A5.993,5.993,0,0,1,3.744.484a6.091,6.091,0,0,1,4.761,0,5.993,5.993,0,0,1,3.26,3.26,6.091,6.091,0,0,1,0,4.761,5.993,5.993,0,0,1-3.26,3.26A6.009,6.009,0,0,1,6.125,12.25Zm0-11.484a5.239,5.239,0,0,0-2.082.425A5.266,5.266,0,0,0,1.19,4.043a5.314,5.314,0,0,0,0,4.163A5.266,5.266,0,0,0,4.043,11.06a5.314,5.314,0,0,0,4.163,0A5.266,5.266,0,0,0,11.06,8.207a5.314,5.314,0,0,0,0-4.163A5.266,5.266,0,0,0,8.207,1.19,5.239,5.239,0,0,0,6.125.766Zm2.3,8.422A1.532,1.532,0,0,1,6.891,7.656,1.531,1.531,0,0,1,9.5,6.574a1.531,1.531,0,0,1,0,2.165A1.475,1.475,0,0,1,8.422,9.188Zm-2.3-3.828A1.532,1.532,0,0,1,4.594,3.828,1.532,1.532,0,0,1,6.125,2.3,1.532,1.532,0,0,1,7.656,3.828,1.532,1.532,0,0,1,6.125,5.359Zm-.766,2.3A1.532,1.532,0,0,1,3.828,9.188,1.532,1.532,0,0,1,2.3,7.656,1.532,1.532,0,0,1,3.828,6.125,1.532,1.532,0,0,1,5.359,7.656Z"
                  />
                </g>
              </svg>
              <span className="list-text">FAQ & Feedback</span>
            </div>
            <div className="list-item">
              <IoBulbOutline className="list-icon" />
              <span className="list-text">Usage Tips</span>
            </div>
            <div className="list-item">
              <PiVideoCameraBold className="list-icon" />
              <svg
                className="list-icon"
                id="Group_69"
                data-name="Group 69"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="12.25"
                viewBox="0 0 14 12.25"
              >
                <rect id="Rectangle_34" data-name="Rectangle 34" width="12.25" height="12.25" fill="none" />
                <g id="bx-video" transform="translate(0 1.852)">
                  <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M15.2,11.225A1.323,1.323,0,0,0,13.8,10H5.4A1.323,1.323,0,0,0,4,11.225V17.35a1.323,1.323,0,0,0,1.4,1.225h8.4a1.323,1.323,0,0,0,1.4-1.225V15.309L18,17.35V11.225l-2.8,2.041ZM13.8,17.35H5.4V11.225h8.4v3.062h0V17.35Z"
                    transform="translate(-4 -10)"
                    fill="#3d3d3d"
                  />
                </g>
              </svg>

              <span className="list-text">SeAMate Tour</span>
            </div>
            <hr />
            <div className="list-item">
              
              <svg
                className="list-icon"
                id="Group_69"
                data-name="Group 69"
                xmlns="http://www.w3.org/2000/svg"
                width="12.25"
                height="12.25"
                viewBox="0 0 12.25 12.25"
              >
                <rect id="Rectangle_34" data-name="Rectangle 34" width="12.25" height="12.25" fill="none" />
                <g id="card-membership" transform="translate(0 0.029)">
                  <g id="Group_75" data-name="Group 75">
                    <g id="Group_74" data-name="Group 74">
                      <g id="Group_73" data-name="Group 73">
                        <path
                          id="Path_49"
                          data-name="Path 49"
                          d="M11.016,0a1.191,1.191,0,0,1,.875.359,1.157,1.157,0,0,1,.359.846V7.947a1.224,1.224,0,0,1-1.234,1.234H8.578v3.041l-2.467-1.2-2.439,1.2V9.181H1.234a1.191,1.191,0,0,1-.875-.359A1.191,1.191,0,0,1,0,7.947V1.205A1.157,1.157,0,0,1,.359.359,1.191,1.191,0,0,1,1.234,0Zm0,7.947V6.714H1.234V7.947Zm0-3.07V1.205H1.234V4.878Z"
                          transform="translate(0 0)"
                          fill="#3d3d3d"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>

              <span className="list-text">Sign Out</span>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );

}