import React, { Component } from 'react';
//import '../../App.css';
import { GoAlertFill } from "react-icons/go";

const Somethingwentwrong = () => {
    return (
        <>
            <div className='App-wrap align-items-center justify-content-center h-100vh bg-theme-gradient'>
                
                <div className='wrap'>
                    <div className='row g-4 text-center '>
                        <div className='col-12 text-center '>
                        <svg width="0" height="0">
                        <linearGradient id="gradient" x1="0%" y1="100%" x2="0%" y2="0%">
                            <stop stopColor="#3B72D3" offset="0%" />
                            <stop stopColor="#36CBB9" offset="100%" />
                        </linearGradient>
                        </svg>
                        <GoAlertFill className='w-92'  style={{ fill: "url(#gradient)",  }}/>
                        </div>
                        <div className='col-12'><h4 className='text-center my-0'>{localStorage.getItem("ErrorMessage")??"Something Went Wrong, Please try again later."}</h4></div>
                       
                    </div>
                </div>

            </div>
        </>
    );
}

export default Somethingwentwrong;
