import React, { useRef, useState, useEffect, useCallback } from "react";
import Header from "./Header";
import { FaArrowLeft } from "react-icons/fa";
import { IoCopyOutline } from "react-icons/io5";
import { PiMicrosoftOutlookLogoFill } from "react-icons/pi";
//import '../../App.css';
import { SiDependabot } from "react-icons/si";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { HiOutlineStar, HiStar } from "react-icons/hi2";

export default function KeyPeoples({ changeScreen }) {
  const headerChangeScreen = (screen) => {
    changeScreen(screen);
  };

  return (
    <div className="quicksend-container hfex">
      <div className="">
        <Header headerChangeScreen={headerChangeScreen} />
      </div>
      <div className="back-wrap ">
        <p className="backIcon">
          <FaArrowLeft
            className="arrow-btn me-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeScreen("Profile");
            }}
          />{" "}
          <span>Key Peoples</span>
        </p>
      </div>

      <div className="compose-bg p-10 min-height-100vh position-relative ">
        <label className="btn nbtn-light text-start w-100 bg-lt p-1 container mb-1">
          <span className="row align-items-center w-100 mx-0">
            <span className="col lh-1">
              <span className="d-block">
                {" "}
                <b>Person Name</b>
                <br />
                <small className="text-theme">Personmail@gmail.com</small>
              </span>
            </span>
            <span className="col-auto">
              <HiOutlineStar />
              {/* <HiStar className="wh-20 svg-fill"/> */}
            </span>
          </span>
        </label>
      </div>
    </div>
  );
}
