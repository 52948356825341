import React, { useEffect, useState } from "react";
import Header from "./Header";
import { FaArrowLeft } from "react-icons/fa";
import userImg from "../../../assets/icon-user.png";
import { MdWorkspacePremium, MdOutlineAccountCircle, MdOutlineInfo } from "react-icons/md";
import { LuChevronRight, LuChevronDown } from "react-icons/lu";
import { BsPersonCheck } from "react-icons/bs";
import { Button } from "react-bootstrap";
import { HiOutlineStar, HiStar } from "react-icons/hi2";
import axios from "axios";
import { mwURL_Global } from '../../global'

export default function Profile({ changeScreen }) {
  //console.log(sessionStorage.getItem("LoginDetails"));
  const LoginDetails = JSON.parse(sessionStorage.getItem("LoginDetails"));
  const [prioritySenderList, setprioritySenderList] = useState([]);

  const [restrictedSenderListEmails, setrestrictedSenderListEmails] = useState([]);
  const [restrictedSenderListDomains, setrestrictedSenderListDomains] = useState([]);
  const [isprioritylistfetched, setisprioritylistfetched] = useState(false);

  const [isrestrictedlistfetched, setisrestrictedlistfetched] = useState(false);
  const aiserviceURL = localStorage.getItem("aiserviceURL");
  const clearCache = () => {
    sessionStorage.clear();
    localStorage.clear();
    Office.context.ui.closeContainer();

  };
  const licenses = [];
  const [isPrimaryLicense, setPrimaryLicense] = useState(true);
  const [isKeyPeopleOpen, setKeyPeopleOpen] = useState(false);
  const [isRestrictedPeopleOpen, setRestrictedPeopleOpen] = useState(false);

  const [isContactOpen, setContactOpen] = useState(false);

  var personadetails = LoginDetails.typeOfPersonaLicense
    .map(function (val) {
      if (val.isPrimary === true) {
        return val.name + " (Primary)";
        setPrimaryLicense(true);
      } else {
        return val.name;
        setPrimaryLicense(false);
      }
      licenses.push(val.name);
    })
    .join(" | ");

  const headerChangeScreen = (screen) => {
    changeScreen(screen);
  };

  useEffect(() => {
    GetPrioritySenderList();

  }, [isprioritylistfetched]);


  useEffect(() => {
    GetRestrictedSenderList();
  }, [setisprioritylistfetched]);

  const GetPrioritySenderList = () => {

    axios
      .get(aiserviceURL + "/single-priority?user_email=" + Office.context.mailbox.userProfile.emailAddress)
      .then(function (response) {
        setprioritySenderList(response.data)

        setisprioritylistfetched(true)
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
        setisprioritylistfetched(false)
      });
  }

  const GetRestrictedSenderList = () => {

    axios
      .get(mwURL_Global + "/v1/getfilterList?useremail=" + Office.context.mailbox.userProfile.emailAddress)
      .then(function (response) {
        setrestrictedSenderListEmails(response.data.result.blockedemails)
        setrestrictedSenderListDomains(response.data.result.blockeddomains)

        setisrestrictedlistfetched(true)
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
        setisrestrictedlistfetched(false)
      });
  }

  const removePrioritySender = (priority_email_in) => {

    axios
      .put(
        aiserviceURL + "/remove-priority",
        {
          user_email: Office.context.mailbox.userProfile.emailAddress,
          priority_email: priority_email_in,
          //priority_name: Office.context.mailbox.item.from.displayName
        },
        { "Content-Type": "multipart/form-data" }
      )
      .then((response) => {
        setisprioritylistfetched(false);
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
  }

  return (
    <div className="quicksend-container">
      <Header headerChangeScreen={headerChangeScreen} />
      <div className="back-wrap">
        <p className="backIcon">
          <FaArrowLeft
            className="arrow-btn me-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeScreen("QuickSend");
            }}
          />{" "}
          <span>Settings</span>
        </p>
      </div>
      <div className="compose-bg height-100vh p-10">
        <div>
          <div className="user-info-container scard bg-theme pb-0">
            {/* <img src={userImg} alt="User" />
             */}
            <div className="container w-100">
              <div className="py-2">
                <h2>{Office.context.mailbox.userProfile.displayName}</h2>
                <p className="m-0">{Office.context.mailbox.userProfile.jobTitle}</p>
                <p className="mb-1">{Office.context.mailbox.userProfile.emailAddress}</p>
              </div>
            </div>
          </div>
          <div className="scard">
            <div className="container w-100 px-0">
              <div className="row g-0 align-items-center" style={{ paddingBottom: 8, paddingTop: 4 }}>
                <div className="col">
                  <p className="txt-main-green align-items-center d-flex my-1 ">
                    {/* <MdOutlineAccountCircle style={{ width: 16, height: 16, marginRight: 4 }} /> */}

                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" className="mr-5 fill-theme-color" viewBox="0 -960 960 960"><path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" /></svg>
                    <span className="heading">Your Persona</span>
                  </p>
                </div>
              </div>
              <div>
                {LoginDetails.typeOfPersonaLicense.map(
                  (i) =>
                    // <ObjectRow obj={quickreply}  />
                    i.isAssigned == true && (
                      <label className={i.isPrimary == true ? "license primary" : "license"}>
                        <span className="max-line-2"> {i.name} </span>
                        <span className="ms-auto">{i.isPrimary == true ? "Primary" : ""}</span>
                      </label>
                    )
                )}


              </div>
            </div>
          </div>


          <div className="profile-list-container scard pt-1 cursorpointer">
            <div className="container w-100 px-0">
              <p
                className="txt-main-green align-items-center d-flex my-1 "
                onClick={() => {
                  setKeyPeopleOpen(!isKeyPeopleOpen);

                  //isContactOpen(false);
                }}
              >
                {/* <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" className="mr-5 fill-theme-color" viewBox="0 0 24 24" ><g fill="none"><path d="M0 0h24v24H0V0z"/><path d="M0 0h24v24H0V0z" opacity=".87"/></g><path d="M11 7h2v2h-2zm1 10c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm0-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" height="17" width="17" className="mr-5 fill-theme-color" viewBox="0 -960 960 960"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80ZM380-420q58 0 99-41t41-99q0-58-41-99t-99-41q-58 0-99 41t-41 99q0 58 41 99t99 41Zm280 40q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29ZM480-160q85 0 155-40t113-106q-21-6-43-10t-45-4q-53 0-128 31T412-167q17 4 34 5.5t34 1.5Zm-127-26q35-72 79.5-107t67.5-47q-29-9-58.5-14.5T380-360q-45 0-89 11t-85 31q26 43 63.5 77.5T353-186Z" /></svg>
                <span className="heading">Priority Senders</span>
                <LuChevronDown className="ms-auto text-dark" />
              </p>
              <div className={!isKeyPeopleOpen ? "ac-pannel" : "ac-pannel d-show"}>


                {prioritySenderList.map((email) => (
                  <label className="btn nbtn-light text-start w-100 bg-lt p-2 container mb-1">
                    <span className="row align-items-center w-100 mx-0 g-0">
                      <span className="col lh-1">
                        <span className="d-block">
                          {" "}
                          <b>{email}</b>
                          {/* <br />
                          <small className="text-theme">{email}</small> */}
                        </span>
                      </span>
                      <span className="col-auto">
                        {/* <HiOutlineStar /> */}
                        <HiStar className="wh-20 svg-fill" onClick={() => {
                          removePrioritySender(
                            email
                          );
                          // setLikeSummery(!isLikedSummery);
                        }} />
                      </span>
                    </span>
                  </label>

                ))}

              </div>
            </div>
          </div>

          {/* Restricted People List */}

          <div className="profile-list-container scard pt-1 cursorpointer">
            <div className="container w-100 px-0">
              <p
                className="txt-main-green align-items-center d-flex my-1 "
                onClick={() => {
                  setRestrictedPeopleOpen(!isRestrictedPeopleOpen);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="17" width="17" className="mr-5 fill-theme-color" viewBox="0 -960 960 960"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80ZM380-420q58 0 99-41t41-99q0-58-41-99t-99-41q-58 0-99 41t-41 99q0 58 41 99t99 41Zm280 40q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29ZM480-160q85 0 155-40t113-106q-21-6-43-10t-45-4q-53 0-128 31T412-167q17 4 34 5.5t34 1.5Zm-127-26q35-72 79.5-107t67.5-47q-29-9-58.5-14.5T380-360q-45 0-89 11t-85 31q26 43 63.5 77.5T353-186Z" /></svg>
                <span className="heading">Restricted Peoples</span>
                <LuChevronDown className="ms-auto text-dark" />
              </p>
              <div className={!isRestrictedPeopleOpen ? "ac-pannel" : "ac-pannel d-show"}>
                <p><b>Email & Email Groups</b></p>
                {restrictedSenderListEmails.map((email) => (
                  <label className="btn nbtn-light text-start w-100 bg-lt p-2 container mb-1">
                    <span className="row align-items-center w-100 mx-0 g-0">
                      <span className="col lh-1">
                        <span className="d-block">
                          {" "}
                          {email}
                          {/* <br />
                          <small className="text-theme">{email}</small> */}
                        </span>
                      </span>
                    </span>
                  </label>

                ))}

                <p><b>Domains</b></p>
                {restrictedSenderListDomains.map((email) => (
                  <label className="btn nbtn-light text-start w-100 bg-lt p-2 container mb-1">
                    <span className="row align-items-center w-100 mx-0 g-0">
                      <span className="col lh-1">
                        <span className="d-block">
                          {" "}
                          {email}
                          {/* <br />
                          <small className="text-theme">{email}</small> */}
                        </span>
                      </span>
                    </span>
                  </label>

                ))}

              </div>
            </div>
          </div>

          {/* <ul>
          <hr className="compose-bg" />
            <li className="font-bold" style={{ paddingTop: "10px" }}>
              Your Settings
            </li>
            <hr className="compose-bg" />
            <li>Webhook Subscribe Status:{LoginDetails.webhook_subscribe}</li>
            <li>Auto Draft Status : {LoginDetails.auto_draft_gen}</li>
          </ul>
           */}

          <div className="profile-list-container scard  pt-1 cursorpointer">
            {/* <ul>
            <li className="font-bold" style={{ paddingTop: "10px" }}>
              Settings
            </li>
            <hr className="compose-bg" />
            <li>Edit Profile</li>
            <hr className="compose-bg" />
            <li>Priority Tags</li>
            <hr className="compose-bg" />
            <li style={{ paddingBottom: "10px" }}>
              Subscription
              <span className="sub-list">
                <sub className="prime-color">
                  <MdWorkspacePremium /> {userData.subscriptionType}
                </sub>
                <sub>{userData.subscriptionDate}</sub>
              </span>
            </li>
          </ul> */}
            <div className="container w-100 px-0">
              <p
                className="txt-main-green align-items-center d-flex my-1 "
                onClick={() => {
                  setContactOpen(!isContactOpen);
                  //isKeyPeopleOpen(false);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" className="mr-5 fill-theme-color" viewBox="0 0 24 24" ><g fill="none"><path d="M0 0h24v24H0V0z" /><path d="M0 0h24v24H0V0z" opacity=".87" /></g><path d="M11 7h2v2h-2zm1 10c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm0-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg>
                <span className="heading">Contact Info</span>
                <LuChevronDown className="ms-auto text-dark" />
              </p>
              <div className={!isContactOpen ? "ac-pannel" : "ac-pannel d-show"}>
                <ul className="mb-1 mt-0">
                  <li>
                    For Support Query -<br /> <a href="mailto:support@nexusoceanai.com">support@nexusoceanai.com</a>
                  </li>
                </ul>
                <ul>
                  <li style={{ paddingBottom: "10px" }}>
                    Visit : <br />
                    <a href="https://www.nexusoceanai.com" target="_blank">
                      www.nexusoceanai.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="profile-list-container">
            <button style={{ marginBottom: "10px" }} className="bg-main-green started-btn w-100" onClick={clearCache}>
              Sign Out
            </button>

            <p>Version : 1.0.0.5</p>
          </div>
        </div>
      </div>
    </div>
  );
}
