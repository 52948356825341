import React, { Component } from 'react';

const FirstScreen = () => {
    return (
      <>
        <div className="loadingwrap bg-white">
          <div>
            <img src="../../assets/new-logo-animated.svg" alt="Initializing..." style={{ width: 64 }} />
            {/* <Logo  style={{ width: 64 }}/> */}
            {/* <h2 className="text-theme my-0">SeAMate</h2>
            <h4 className="text-center my-0">Initializing...</h4> */}
          </div>
        </div>
      </>
    );
}

export default FirstScreen;
