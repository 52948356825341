import React, { useRef, useState, useEffect, useCallback } from "react";
import Header from "./Header";
import { CiStar } from "react-icons/ci";
import { IoCopyOutline } from "react-icons/io5";
import { MdRefresh } from "react-icons/md";
import { IoLanguage } from "react-icons/io5";
import { PiMicrosoftOutlookLogoFill } from "react-icons/pi";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { Parser } from "html-to-react";
import Loading from "./loading";
import { TbHandClick } from "react-icons/tb";
import { BiCommentEdit, BiDislike, BiLike, BiSolidLike, BiSolidDislike } from "react-icons/bi";
import { HiOutlineStar, HiStar } from "react-icons/hi2";
import { like, disLike, comment } from "../../likeservice";
import { checkRestrictedSender } from "../../restrictedSenderService";
import { CSSTransition } from "react-transition-group";

import load from "../../../assets/logo-icon.gif";
import Webimg from "../../../assets/web-icon.png";
import { IoIosArrowDown } from "react-icons/io";
// <IoIosArrowDown />

import { IoIosArrowUp } from "react-icons/io";

import { mwURL_Global } from '../../global'
import Loading_summary from "./loading_summary";
import Loading_Attachment from "./Loading_Attachment";
import Loading_Draft from "./Loading_Draft";
import Loading_Detailedsummary from "./Loading_Detailedsummary";
// <IoIosArrowUp />

export default function QuickSend({ changeScreen }) {

  const aiserviceURL = localStorage.getItem("aiserviceURL");


  const [summary, setSummary] = useState("");

  const [draft, setDraft] = useState("");
  const [quickreply, setQuickreply] = useState([]);
  const [isInfoFetched, setIsInfoFetched] = useState(false);
  const [emailbodyNew, setemailbodyNew] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingdraft, setIsLoadingdraft] = useState(false);
  const [customquickreply, setCustomQuickreply] = useState("");
  const [customtextButton, setcustomtextButton] = useState(false);
  const ref = useRef(null);
  const [AttachmentList, setAttachmentList] = useState([]);
  const [attachmentDropdownValue, setattachmentDropdownValue] = useState(0);
  const [attachmentSummary, setattachmentSummary] = useState("");
  const [isLoadingattachmentSummary, setisLoadingattachmentSummary] = useState(false);

  const [summaryLanguage, setsummaryLanguage] = useState(localStorage.getItem("summaryLanguage") ?? "en-US");
  const [draftLanguage, setDraftLanguage] = useState(localStorage.getItem("draftLanguage") ?? "en-US");

  const [isPrioritySender, setisPrioritySender] = useState(false);
  const [prioritySenderList, setprioritySenderList] = useState([]);
  const [attachmentsummaryLanguage, setattachmentsummaryLanguage] = useState(
    localStorage.getItem("attachmentsummaryLanguage") ?? "en-US"
  );

  const [usecacheSummary, setusecacheSummary] = useState(false);
  const [usecacheDraft, setusecacheDraft] = useState(true);
  const [usecacheAttachmentSummary, setusecacheAttachmentSummary] = useState(false);

  const [disableButtons, setdisableButtons] = useState(false);
  const [disableDraftButtons, setdisableDraftButtons] = useState(false);
  const [disableqreplyButtons, setdisableqreplyButtons] = useState(false);
  const [isDetailedSummary, setisDetailedSummary] = useState(false);
  const [customreplyvalidation, setcustomreplyvalidation] = useState("");
  const [IsLicenseInfoFetched, setIsLicenseInfoFetched] = useState(false);
  const [isRestrictedSender, setisRestrictedSender] = useState(false);

  useEffect(() => {
    checkLicense();

  }, [IsLicenseInfoFetched]);

  const checkLicense = () => {

    axios
      .get(mwURL_Global + "/license/v2/license/check", {
        params: {
          email: Office.context.mailbox.userProfile.emailAddress,
        },
      })
      .then((response) => {
        setIsLicenseInfoFetched(true);
        if (response.data.code === 200) {

          if (response.data.result.result.domainExists == true && response.data.result.result.license == true) {
            sessionStorage.setItem("LoginDetails", JSON.stringify(response.data.result.result))

            localStorage.setItem("aiserviceURL", response.data.result.result.aiserviceURL);
            //localStorage.setItem("aiserviceURL","http://127.0.0.1:8080");

            if (response.data.result.result.aiserviceURL === "") {
              console.log("1", response.data.result.result.aiserviceURL)
              localStorage.setItem("ErrorMessage", "AI Service URL is not set");
              setCurrentScreen("Somethingwentwrong");
            } else if (response.data.result.result.aiserviceURL === undefined) {
              console.log("2", response.data.result.result.aiserviceURL)
              localStorage.setItem("ErrorMessage", "AI Service URL is not set");
              setCurrentScreen("Somethingwentwrong");
            } else if (response.data.result.result.aiserviceURL === null) {
              console.log("3", response.data.result.result.aiserviceURL)
              localStorage.setItem("ErrorMessage", "AI Service URL is not set");
              setCurrentScreen("Somethingwentwrong");
            }



          } else {
            setCurrentScreen("Expired");
          }
        } else {
          localStorage.setItem("ErrorMessage", response.data.message);
          setCurrentScreen("Somethingwentwrong");
        }


        console.log("Response:", response.data);
        // Process the response data here
      })
      .catch((error) => {
        // Handle any errors
        localStorage.setItem("ErrorMessage", "Something Went Wrong");
        setCurrentScreen("Somethingwentwrong");
        console.error("Error:", error);
      });

  }

  const htmlParser = new Parser();

  useEffect(() => {
    setusecacheDraft(false);
    if (customquickreply != null && customquickreply != "") {
      getDraftStream();

    }
  }, [customquickreply]);

  const handleattachmentDropdownValueChange = (e) => {
    setattachmentDropdownValue(e.target.value);
  };

  const handleSummaryLanguageDropdownValueChange = (e) => {
    setsummaryLanguage(e.target.value);
    localStorage.setItem("summaryLanguage", e.target.value);

    getSummaryStream();
    getQuickReply();
  };

  const handleDraftLanguageDropdownValueChange = (e) => {
    setDraftLanguage(e.target.value);

    localStorage.setItem("draftLanguage", e.target.value);
    getDraftStream();
  };
  const handleattachmentSummarylanguageDropdownValueChange = (e) => {
    setattachmentsummaryLanguage(e.target.value);
    localStorage.setItem("attachmentsummaryLanguage", e.target.value);
  };
  const handleCopydraft = () => {
    // const textArea = document.createElement("textarea");
    // textArea.value = draft;
    // document.body.appendChild(textArea);
    // textArea.select();
    // document.execCommand("copy");
    // document.body.removeChild(textArea);
    navigator.clipboard.writeText(draft);
  };

  const handleCopysummary = () => {
    const textArea = document.createElement("textarea");
    textArea.value = summary;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  // function itemChanged(eventArgs) {
  //   console.log("Item changed called");
  //   UpdateTaskPaneUI(Office.context.mailbox.item);
  // }
  const itemChanged = useCallback((e) => {
    if (Office.context.mailbox.item.displayReplyForm != undefined) {

      console.log("Item changed called");
      setisDetailedSummary(false);
      setQuickreply([]);
      setDraft("");
      setSummary("");
      setattachmentSummary("");
      setCustomQuickreply("");
      UpdateTaskPaneUI(Office.context.mailbox.item);
      setSelectedDropdown(1);
      setusecacheSummary(false);
      setusecacheDraft(true);
      setusecacheAttachmentSummary(true);

    } else {
      setCurrentScreen("ComposeMail");
    }
  }, []);
  const UpdateTaskPaneUI = async (item) => {
    // Assuming that item is always a read item (instead of a compose item).
    if (item != null) {
      setIsInfoFetched(false);
      getEmailBody();
    }
  };
  Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, itemChanged);

  useEffect(() => {
    getEmailBody();
  }, []);

  useEffect(() => {

    if (isInfoFetched) {
      setusecacheSummary(false);
      getSummaryStream();
      getQuickReply();
    }
  }, [isInfoFetched]);

  //Get access token
  function getAccessToken() {
    return new Promise(function (resolve, reject) {
      Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, function (result) {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          // console.log(result.value);
          let data = parseJwt(result.value);
          let data1 = JSON.parse(data);
          console.log("Access Token" + data);
          window.localStorage.setItem("userEmail", data1.smtp);

          resolve(result.value);
        } else {
          reject("Error obtaining access token");
        }
      });
    });
  }
  const [selectedDropdown, setSelectedDropdown] = useState(1);

  const dropdownOptions = [
    { id: 1, label: "Summary" },
    { id: 2, label: "Quick Draft" },
    { id: 3, label: "Attachment Summary" },
  ];

  const handleDropdownClick = (dropdownId, e) => {
    e.stopPropagation();

    if (selectedDropdown === dropdownId) {
      // Close the dropdown if it's already open
      setSelectedDropdown(null);
    } else {
      // Open the selected dropdown
      setSelectedDropdown(dropdownId);
    }

    if (dropdownId === 2) {
      if (draft === "") {
        getDraftStreamCache();
        //streamData();
      }
    } else if (dropdownId == 3) {
      console.log("Attachments called");
    } else if (dropdownId == 4) {
      console.log("prioritySenderList2", prioritySenderList)
      setisPrioritySender(prioritySenderList.includes(Office.context.mailbox.item.from.emailAddress))

    }
    else {

    }
  };

  const headerChangeScreen = (screen) => {
    changeScreen(screen);
  };

  const getEmailBody = () => {
    const item = Office.context.mailbox.item;
    let emailDetails = "";

    emailDetails += "From: " + (item.from && item.from.emailAddress) + "\n";
    emailDetails += "Date: " + (item.dateTimeCreated && item.dateTimeCreated.toLocaleString()) + "\n";
    emailDetails += "To: " + (item.to && item.to.map((recipient) => recipient.emailAddress).join(", ")) + "\n";
    emailDetails += "Subject: " + item.subject + "\n\n";

    item.body.getAsync("text", function (result) {
      if (result.status === "succeeded") {
        emailDetails += "Body:\n" + result.value;
        setemailbodyNew(emailDetails);
        setIsInfoFetched(true);
      } else {
        console.error("Failed to get the email body. Error:", result.error);
      }
    });
  };

  const getSummary = () => {
    setIsLoading(true);

    var recipientto = Office.context.mailbox.item.to
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    var recipientcc = Office.context.mailbox.item.cc
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    var recipientbcc = Office.context.mailbox.item.bcc
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    axios
      .post(
        aiserviceURL + "/generate-summary",
        {
          persona: sessionStorage.getItem("primarypersona") ?? "HSEQ / Marine",
          language: localStorage.getItem("summaryLanguage") ?? "en-US",
          tone: "Professional",
          stream: false,
          enable_llm_firewall: false,
          email_body: emailbodyNew,
          from_email: Office.context.mailbox.item.sender.emailAddress,
          to_email: recipientto,
          cc_email: recipientcc,
          bcc_email: recipientbcc,
          user_email: Office.context.mailbox.userProfile.emailAddress,
          message_id: Office.context.mailbox.item.itemId,
          subject: Office.context.mailbox.item.subject,
          importance: Office.context.mailbox.item.importance,
          hasAttachments: Office.context.mailbox.hasAttachments,
          displayName: Office.context.mailbox.userProfile.displayName,
          jobTitle: Office.context.mailbox.userProfile.jobTitle ?? "",
          preferredLanguage: localStorage.getItem("summaryLanguage") ?? "en-US",
          officeLocation: Office.context.mailbox.userProfile.officeLocation ?? "",
          managerdisplayName: Office.context.mailbox.userProfile.managerdisplayName ?? "",
          managerjobTitle: Office.context.mailbox.userProfile.managerjobTitle ?? "",
          managerOfficeLocation: Office.context.mailbox.userProfile.managerOfficeLocation ?? "",
          cite_external_sources: false,
          use_cached_response: false,
        },
        { "Content-Type": "application/json" }
      )
      .then((response) => {
        setIsLoading(false);
        setSummary(response.data.email_summary.consolidated_summary);
        //setQuickreply(response.data.email_summary.quick_reply);
        getQuickReply();
      })
      .catch((error) => {
        // Handle any errors
        setIsLoading(false);
        console.error("Error:", error);
      });
  };

  const getSummaryStream = async () => {

    const isRestricted = await checkRestrictedSender(Office.context.mailbox.userProfile.emailAddress, Office.context.mailbox.item.sender.emailAddress)

    if (isRestricted != true) {

      setisRestrictedSender(false)
      setisDetailedSummary(false);
      console.log("getSummaryStream called");

      setIsLoading(true);
      setSummary("");
      setdisableButtons(true);
      const messageID = Office.context.mailbox.item.itemId;

      var recipientto = Office.context.mailbox.item.to
        .map(function (val) {
          return val.emailAddress;

        })
        .join(",");

      var recipientcc = Office.context.mailbox.item.cc
        .map(function (val) {
          return val.emailAddress;
        })
        .join(",");

      var recipientbcc = Office.context.mailbox.item.bcc
        .map(function (val) {
          return val.emailAddress;
        })
        .join(",");

      try {
        const response = await fetch(aiserviceURL + "/generate-summary-only", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            persona: sessionStorage.getItem("primarypersona") ?? "HSEQ / Marine",
            language: localStorage.getItem("summaryLanguage") ?? "en-US",
            tone: "Professional",
            stream: true,
            enable_llm_firewall: false,
            email_body: emailbodyNew,
            from_email: Office.context.mailbox.item.sender.emailAddress,
            to_email: recipientto,
            cc_email: recipientcc,
            bcc_email: recipientbcc,
            user_email: Office.context.mailbox.userProfile.emailAddress,
            message_id: Office.context.mailbox.item.itemId,
            subject: Office.context.mailbox.item.subject,
            importance: Office.context.mailbox.item.importance,
            hasAttachments: Office.context.mailbox.hasAttachments,
            displayName: Office.context.mailbox.userProfile.displayName,
            jobTitle: Office.context.mailbox.userProfile.jobTitle ?? "",
            preferredLanguage: localStorage.getItem("summaryLanguage") ?? "en-US",
            officeLocation: Office.context.mailbox.userProfile.officeLocation ?? "",
            managerdisplayName: Office.context.mailbox.userProfile.managerdisplayName ?? "",
            managerjobTitle: Office.context.mailbox.userProfile.managerjobTitle ?? "",
            managerOfficeLocation: Office.context.mailbox.userProfile.managerOfficeLocation ?? "",
            cite_external_sources: false,
            use_cached_response: usecacheSummary,
            use_all_threads: false,
          }),
        });

        if (!response.ok) {
          setIsLoading(false);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        setisDetailedSummary(false);
        setIsLoading(false);
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done || messageID != Office.context.mailbox.item.itemId) break;
          const chunk = decoder.decode(value);
          setSummary((prev) => prev + chunk);
        }
        GetPrioritySenderList();
        setusecacheSummary(false);
      } catch (e) {
        setError(e.message);
      } finally {
        setdisableButtons(false);
      }
    } else {
      setisRestrictedSender(true)
      //setSummary("Restricted Sender, Seamate Can not Read content of This email due to security restrictions")
    }
  };

  const getQuickReply = () => {
    const isRestricted = checkRestrictedSender(Office.context.mailbox.userProfile.emailAddress, Office.context.mailbox.item.sender.emailAddress)
    if (isRestricted != true) {
      setisRestrictedSender(false)

      var recipientto = Office.context.mailbox.item.to
        .map(function (val) {
          return val.emailAddress;
        })
        .join(",");

      var recipientcc = Office.context.mailbox.item.cc
        .map(function (val) {
          return val.emailAddress;
        })
        .join(",");

      var recipientbcc = Office.context.mailbox.item.bcc
        .map(function (val) {
          return val.emailAddress;
        })
        .join(",");

      axios
        .post(
          aiserviceURL + "/generate-summary-quickreply",
          {
            persona: sessionStorage.getItem("primarypersona") ?? "HSEQ / Marine",
            language: localStorage.getItem("summaryLanguage") ?? "en-US",
            tone: "Professional",
            stream: false,
            enable_llm_firewall: false,
            email_body: emailbodyNew,
            from_email: Office.context.mailbox.item.sender.emailAddress,
            to_email: recipientto,
            cc_email: recipientcc,
            bcc_email: recipientbcc,
            user_email: Office.context.mailbox.userProfile.emailAddress,
            message_id: Office.context.mailbox.item.itemId,
            subject: Office.context.mailbox.item.subject,
            importance: Office.context.mailbox.item.importance,
            hasAttachments: Office.context.mailbox.hasAttachments,
            displayName: Office.context.mailbox.userProfile.displayName,
            jobTitle: Office.context.mailbox.userProfile.jobTitle ?? "",
            preferredLanguage: localStorage.getItem("summaryLanguage") ?? "en-US",
            officeLocation: Office.context.mailbox.userProfile.officeLocation ?? "",
            managerdisplayName: Office.context.mailbox.userProfile.managerdisplayName ?? "",
            managerjobTitle: Office.context.mailbox.userProfile.managerjobTitle ?? "",
            managerOfficeLocation: Office.context.mailbox.userProfile.managerOfficeLocation ?? "",
            cite_external_sources: false,
            use_cached_response: false,
          },
          { "Content-Type": "application/json" }
        )
        .then((response) => {
          setIsLoading(false);

          setQuickreply(response.data);
        })
        .catch((error) => {
          // Handle any errors
          setIsLoading(false);
          console.error("Error:", error);
        });

      // handle like dislike states
      setLikeSummary(false);
      setDisLikeSummary(false);
      setLikeSummaryDisable(false);
      setDislikeSummaryDisable(false);

    }
    else {
      setisRestrictedSender(true)

    }
  };

  const getDetailedSummary = () => {
    setIsLoading(true);

    var recipientto = Office.context.mailbox.item.to
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    var recipientcc = Office.context.mailbox.item.cc
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    var recipientbcc = Office.context.mailbox.item.bcc
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    axios
      .post(
        aiserviceURL + "/detailed-summary",
        {
          persona: sessionStorage.getItem("primarypersona") ?? "HSEQ / Marine",
          language: localStorage.getItem("summaryLanguage") ?? "en-US",
          tone: "Professional",
          stream: false,
          enable_llm_firewall: false,
          email_body: emailbodyNew,
          from_email: Office.context.mailbox.item.sender.emailAddress,
          to_email: recipientto,
          cc_email: recipientcc,
          bcc_email: recipientbcc,
          user_email: Office.context.mailbox.userProfile.emailAddress,
          message_id: Office.context.mailbox.item.itemId,
          subject: Office.context.mailbox.item.subject,
          importance: Office.context.mailbox.item.importance,
          hasAttachments: Office.context.mailbox.hasAttachments,
          displayName: Office.context.mailbox.userProfile.displayName,
          jobTitle: Office.context.mailbox.userProfile.jobTitle ?? "",
          preferredLanguage: localStorage.getItem("summaryLanguage") ?? "en-US",
          officeLocation: Office.context.mailbox.userProfile.officeLocation ?? "",
          managerdisplayName: Office.context.mailbox.userProfile.managerdisplayName ?? "",
          managerjobTitle: Office.context.mailbox.userProfile.managerjobTitle ?? "",
          managerOfficeLocation: Office.context.mailbox.userProfile.managerOfficeLocation ?? "",
          cite_external_sources: false,
          use_cached_response: false,
          short_summary: "",
        },
        { "Content-Type": "application/json" }
      )
      .then((response) => {
        setIsLoading(false);
        setSummary(response.data.detailed_summary);
        //setQuickreply(response.data.email_summary.quick_reply);
      })
      .catch((error) => {
        // Handle any errors
        setIsLoading(false);
        console.error("Error:", error);
      });

    // handle like dislike states
    setLikeSummary(false);
    setDisLikeSummary(false);
    setLikeSummaryDisable(false);
    setDislikeSummaryDisable(false);
  };

  const getDetailedSummaryStream = async () => {
    setisDetailedSummary(true);
    setIsLoading(true);
    setSummary("");
    setdisableButtons(true);
    const messageID = Office.context.mailbox.item.itemId;
    var recipientto = Office.context.mailbox.item.to
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    var recipientcc = Office.context.mailbox.item.cc
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    var recipientbcc = Office.context.mailbox.item.bcc
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    try {
      const response = await fetch(aiserviceURL + "/detailed-summary", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          persona: sessionStorage.getItem("primarypersona") ?? "HSEQ / Marine",
          language: localStorage.getItem("summaryLanguage") ?? "en-US",
          tone: "Professional",
          stream: true,
          enable_llm_firewall: false,
          email_body: emailbodyNew,
          from_email: Office.context.mailbox.item.sender.emailAddress,
          to_email: recipientto,
          cc_email: recipientcc,
          bcc_email: recipientbcc,
          user_email: Office.context.mailbox.userProfile.emailAddress,
          message_id: Office.context.mailbox.item.itemId,
          subject: Office.context.mailbox.item.subject,
          importance: Office.context.mailbox.item.importance,
          hasAttachments: Office.context.mailbox.hasAttachments,
          displayName: Office.context.mailbox.userProfile.displayName,
          jobTitle: Office.context.mailbox.userProfile.jobTitle ?? "",
          preferredLanguage: localStorage.getItem("summaryLanguage") ?? "en-US",
          officeLocation: Office.context.mailbox.userProfile.officeLocation ?? "",
          managerdisplayName: Office.context.mailbox.userProfile.managerdisplayName ?? "",
          managerjobTitle: Office.context.mailbox.userProfile.managerjobTitle ?? "",
          managerOfficeLocation: Office.context.mailbox.userProfile.managerOfficeLocation ?? "",
          cite_external_sources: false,
          use_cached_response: false,
          short_summary: "",
          use_all_threads: true,
        }),
      });

      if (!response.ok) {
        setIsLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setIsLoading(false);
      setisDetailedSummary(true);
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done || messageID != Office.context.mailbox.item.itemId) break;
        const chunk = decoder.decode(value);
        setSummary((prev) => prev + chunk);
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setdisableButtons(false);
    }

    // handle like dislike states
    setLikeSummary(false);
    setDisLikeSummary(false);
    setLikeSummaryDisable(false);
    setDislikeSummaryDisable(false);
  };

  const getDraft = () => {
    setIsLoadingdraft(true);
    var recipientto = Office.context.mailbox.item.to
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    var recipientcc = Office.context.mailbox.item.cc
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    var recipientbcc = Office.context.mailbox.item.bcc
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    axios
      .post(
        aiserviceURL + "/generate-draft",
        {
          persona: sessionStorage.getItem("primarypersona") ?? "HSEQ / Marine",
          language: localStorage.getItem("draftLanguage") ?? "en-US",
          tone: "Professional",
          stream: false,
          enable_llm_firewall: false,
          email_body: emailbodyNew,
          from_email: Office.context.mailbox.item.sender.emailAddress,
          to_email: recipientto,
          cc_email: recipientcc,
          bcc_email: recipientbcc,
          user_email: Office.context.mailbox.userProfile.emailAddress,
          message_id: Office.context.mailbox.item.itemId,
          subject: Office.context.mailbox.item.subject,
          importance: Office.context.mailbox.item.importance,
          hasAttachments: Office.context.mailbox.hasAttachments,
          displayName: Office.context.mailbox.userProfile.displayName,
          jobTitle: Office.context.mailbox.userProfile.jobTitle ?? "",
          preferredLanguage: localStorage.getItem("draftLanguage") ?? "en-US",
          officeLocation: Office.context.mailbox.userProfile.officeLocation ?? "",
          managerdisplayName: Office.context.mailbox.userProfile.managerdisplayName ?? "",
          managerjobTitle: Office.context.mailbox.userProfile.managerjobTitle ?? "",
          managerOfficeLocation: Office.context.mailbox.userProfile.managerOfficeLocation ?? "",
          cite_external_sources: false,
          use_cached_response: false,
          response_strategy: {},
          user_prompt: customquickreply,
        },
        { "Content-Type": "application/json" }
      )
      .then((response) => {
        setIsLoadingdraft(false);
        setDraft(response.data.draft_email_response.draft_body);
      })
      .catch((error) => {
        // Handle any errors
        setIsLoadingdraft(false);
        console.error("Error:", error);
      });

    // handle like dislike states
    setLikeDraft(false);
    setDisLikeDraft(false);
    setLikeDraftDisable(false);
    setDislikeDraftDisable(false);
  };

  const getDraftStream = async () => {
    setIsLoadingdraft(true);
    setDraft("");
    setdisableDraftButtons(true);
    setdisableqreplyButtons(true);
    const messageID = Office.context.mailbox.item.itemId;
    var recipientto = Office.context.mailbox.item.to
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    var recipientcc = Office.context.mailbox.item.cc
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    var recipientbcc = Office.context.mailbox.item.bcc
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    try {
      const response = await fetch(aiserviceURL + "/generate-draft", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          persona: sessionStorage.getItem("primarypersona") ?? "HSEQ / Marine",
          language: localStorage.getItem("draftLanguage") ?? "en-US",
          tone: "Professional",
          stream: true,
          enable_llm_firewall: false,
          email_body: emailbodyNew,
          from_email: Office.context.mailbox.item.sender.emailAddress,
          to_email: recipientto,
          cc_email: recipientcc,
          bcc_email: recipientbcc,
          user_email: Office.context.mailbox.userProfile.emailAddress,
          message_id: Office.context.mailbox.item.itemId,
          subject: Office.context.mailbox.item.subject,
          importance: Office.context.mailbox.item.importance,
          hasAttachments: Office.context.mailbox.hasAttachments,
          displayName: Office.context.mailbox.userProfile.displayName,
          jobTitle: Office.context.mailbox.userProfile.jobTitle ?? "",
          preferredLanguage: localStorage.getItem("draftLanguage") ?? "en-US",
          officeLocation: Office.context.mailbox.userProfile.officeLocation ?? "",
          managerdisplayName: Office.context.mailbox.userProfile.managerdisplayName ?? "",
          managerjobTitle: Office.context.mailbox.userProfile.managerjobTitle ?? "",
          managerOfficeLocation: Office.context.mailbox.userProfile.managerOfficeLocation ?? "",
          cite_external_sources: false,
          use_cached_response: false,
          response_strategy: {},
          user_prompt: customquickreply,
        }),
      });

      if (!response.ok) {
        setIsLoadingdraft(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setIsLoadingdraft(false);
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done || messageID != Office.context.mailbox.item.itemId) break;
        const chunk = decoder.decode(value);
        setDraft((prev) => prev + chunk);
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setdisableDraftButtons(false);
      setdisableqreplyButtons(false);
    }

    setusecacheDraft(false);
    // handle like dislike states
    setLikeDraft(false);
    setDisLikeDraft(false);
    setLikeDraftDisable(false);
    setDislikeDraftDisable(false);
  };

  const getDraftStreamCache = async () => {
    setIsLoadingdraft(true);
    setDraft("");
    setdisableDraftButtons(true);
    setdisableqreplyButtons(true);
    const messageID = Office.context.mailbox.item.itemId;
    var recipientto = Office.context.mailbox.item.to
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    var recipientcc = Office.context.mailbox.item.cc
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    var recipientbcc = Office.context.mailbox.item.bcc
      .map(function (val) {
        return val.emailAddress;
      })
      .join(",");

    try {
      const response = await fetch(aiserviceURL + "/generate-draft", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          persona: sessionStorage.getItem("primarypersona") ?? "HSEQ / Marine",
          language: localStorage.getItem("draftLanguage") ?? "en-US",
          tone: "Professional",
          stream: true,
          enable_llm_firewall: false,
          email_body: emailbodyNew,
          from_email: Office.context.mailbox.item.sender.emailAddress,
          to_email: recipientto,
          cc_email: recipientcc,
          bcc_email: recipientbcc,
          user_email: Office.context.mailbox.userProfile.emailAddress,
          message_id: Office.context.mailbox.item.itemId,
          subject: Office.context.mailbox.item.subject,
          importance: Office.context.mailbox.item.importance,
          hasAttachments: Office.context.mailbox.hasAttachments,
          displayName: Office.context.mailbox.userProfile.displayName,
          jobTitle: Office.context.mailbox.userProfile.jobTitle ?? "",
          preferredLanguage: localStorage.getItem("draftLanguage") ?? "en-US",
          officeLocation: Office.context.mailbox.userProfile.officeLocation ?? "",
          managerdisplayName: Office.context.mailbox.userProfile.managerdisplayName ?? "",
          managerjobTitle: Office.context.mailbox.userProfile.managerjobTitle ?? "",
          managerOfficeLocation: Office.context.mailbox.userProfile.managerOfficeLocation ?? "",
          cite_external_sources: false,
          use_cached_response: false,
          response_strategy: {},
          user_prompt: customquickreply,
        }),
      });

      if (!response.ok) {
        setIsLoadingdraft(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setIsLoadingdraft(false);
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done || messageID != Office.context.mailbox.item.itemId) break;
        const chunk = decoder.decode(value);
        setDraft((prev) => prev + chunk);
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setdisableDraftButtons(false);
      setdisableqreplyButtons(false);
    }

    setusecacheDraft(false);
    // handle like dislike states
    setLikeDraft(false);
    setDisLikeDraft(false);
    setLikeDraftDisable(false);
    setDislikeDraftDisable(false);
  };

  //attachment Related code

  const getattachmentSummaryContent = (index) => {
    setdisableButtons(true);
    setattachmentSummary("");
    setisLoadingattachmentSummary(true);

    const item = Office.context.mailbox.item;

    item.getAttachmentContentAsync(item.attachments[index].id, (result2) => {
      // console.log(result2);
      axios
        .post(
          aiserviceURL + "/upload-attachment-base64",
          {
            user_email: Office.context.mailbox.userProfile.emailAddress,
            file_content: result2.value.content,
            file_name: item.attachments[index].name,
            file_id: item.attachments[index].id,
            message_id: item.itemId,
            file_size: item.attachments[index].size,
            attachment_type: "file",
            language: localStorage.getItem("attachmentsummaryLanguage") ?? "en-US",
          },
          { "Content-Type": "multipart/form-data" }
        )
        .then((response) => {
          // console.log("Attachment Summary",response.data.attachment_summary);
          setattachmentSummary(response.data.attachment_summary);
          setisLoadingattachmentSummary(false);
          setdisableButtons(false);
        })
        .catch((error) => {
          // Handle any errors
          setisLoadingattachmentSummary(false);
          console.error("Error:", error);
          setdisableButtons(false);
        });
    });
    // handle like dislike states
    setLikeAttachmentSummary(false);
    setDisLikeAttachmentSummary(false);
    setLikeAttachmentSummaryDisable(false);
    setDislikeAttachmentSummaryDisable(false);
  };

  const copytoOutlook = () => {
    console.log("draft", draft)
    Office.context.mailbox.item.displayReplyAllForm({
      htmlBody:
        "<html><body><div>" +
        draft.replaceAll("\n", "<br/>") +
        "</div></body></html>",
    });
  };

  // const copytoOutlook = () => {
  //   Office.context.mailbox.item.displayReplyAllForm({
  //     htmlBody:
  //       "<pre style='font-family:inherit; font-size:inherit'>" +
  //       draft +
  //       "</pre>",
  //   });
  // };


  const [isMaskVisible, setIsMaskVisible] = React.useState(true);
  setTimeout(() => {
    setIsMaskVisible(false);
  }, 1500);

  //customequickdraft / like dislike icons visbility & Activity
  const [isCostomQuickDraft, setCustomQuickDraftBox] = useState(false);
  const [isLikedSummary, setLikeSummary] = useState(false);
  const [isDisLikedSummary, setDisLikeSummary] = useState(false);
  const [isLikedDraft, setLikeDraft] = useState(false);
  const [isDisLikedDraft, setDisLikeDraft] = useState(false);
  const [isLikedAttachmentSummary, setLikeAttachmentSummary] = useState(false);
  const [isDisLikedAttachmentSummary, setDisLikeAttachmentSummary] = useState(false);
  const [isGenrateAttachmentSummary, setGenratekeAttachmentSummary] = useState(false);

  const [isDislikeSummaryDisable, setDislikeSummaryDisable] = useState(false);
  const [isLikeSummaryDisable, setLikeSummaryDisable] = useState(false);
  const [isDislikeDraftDisable, setDislikeDraftDisable] = useState(false);
  const [isLikeDraftDisable, setLikeDraftDisable] = useState(false);
  const [isDislikeAttachmentSummaryDisable, setDislikeAttachmentSummaryDisable] = useState(false);
  const [isLikeAttachmentSummaryDisable, setLikeAttachmentSummaryDisable] = useState(false);

  const handleCustomReplyCancel = () => {
    setCustomQuickDraftBox(false);
    setCustomQuickreply("");
    setcustomreplyvalidation("")
  };
  const handleQuickReplyBox = () => {
    setCustomQuickDraftBox(!isCostomQuickDraft);
  };

  const handleCustomReplyClick = (event) => {
    // 👇️ access textarea value
    if (ref.current.value == "" || ref.current.value == null || ref.current.value == undefined) {
      setcustomreplyvalidation("Enter Custom Reply Message")
    } else {
      console.log(ref.current.value);
      setcustomreplyvalidation("")
      setCustomQuickreply(ref.current.value);
      setSelectedDropdown(2);
      setCustomQuickDraftBox(!isCostomQuickDraft);
    }
  };
  const quickReplyButton = (qr) => {
    console.log("Button Cclick" + qr);
    setcustomtextButton(true);
    setCustomQuickreply(qr);
    setSelectedDropdown(2);
    // getDraft();
  };

  //--------//

  const [isCommentBoxSummary, setCommentBoxSummary] = useState(false);
  const [isCommentBoxDraft, setCommentBoxDraft] = useState(false);
  const [isCommentBoxAttachment, setCommentBoxAttachment] = useState(false);
  //  const [comment, setComment] = useState('');
  const [commentSummary, setCommentSummary] = useState("");
  const [commentDraft, setCommentDraft] = useState("");
  const [commentAttachment, setCommentAttachment] = useState("");
  const ref1 = useRef(null);

  const handleCommentIconClickSummary = () => {
    setCommentBoxSummary(!isCommentBoxSummary);
  };
  const handleCommentIconClickDraft = () => {
    setCommentBoxDraft(!isCommentBoxDraft);
  };
  const handleCommentIconClickAttachment = () => {
    setCommentBoxAttachment(!isCommentBoxAttachment);
  };

  const handlePostCommentSummary = () => {
    console.log("Posted comment:", commentSummary);

    comment(
      Office.context.mailbox.item.itemId,
      "Summary",
      Office.context.mailbox.userProfile.emailAddress,
      commentSummary
    );

    setCommentBoxSummary(false);
    setCommentSummary("");
  };
  const handlePostCommentDraft = () => {
    console.log("Posted comment:", commentDraft);

    comment(
      Office.context.mailbox.item.itemId,
      "Draft",
      Office.context.mailbox.userProfile.emailAddress,
      ref1.current.value
    );

    setCommentBoxDraft(false);
    setCommentDraft("");
  };

  const handlePostCommentAttachment = () => {
    console.log("Posted comment:", commentAttachment);
    comment(
      Office.context.mailbox.item.itemId,
      "AttachmentSummary",
      Office.context.mailbox.userProfile.emailAddress,
      commentAttachment
    );

    setCommentBoxAttachment(false);
    setCommentAttachment("");
  };

  const handleCommentCancel = () => {
    setCommentBoxAttachment(false);
    setCommentAttachment("");

    setCommentBoxDraft(false);
    setCommentDraft("");

    setCommentBoxSummary(false);
    setCommentSummary("");
  };


  //priority sender code
  const addPrioritySender = () => {

    axios
      .post(
        aiserviceURL + "/create-priority",
        {
          user_email: Office.context.mailbox.userProfile.emailAddress,
          priority_email: Office.context.mailbox.item.from.emailAddress,
          priority_name: Office.context.mailbox.item.from.displayName
        },
        { "Content-Type": "multipart/form-data" }
      )
      .then((response) => {
        setisPrioritySender(true);
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });

  }

  const removePrioritySender = () => {

    axios
      .put(
        aiserviceURL + "/remove-priority",
        {
          user_email: Office.context.mailbox.userProfile.emailAddress,
          priority_email: Office.context.mailbox.item.from.emailAddress,
          priority_name: Office.context.mailbox.item.from.displayName
        },
        { "Content-Type": "multipart/form-data" }
      )
      .then((response) => {
        setisPrioritySender(false);
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
  }

  const GetPrioritySenderList = () => {

    axios
      .get(aiserviceURL + "/single-priority?user_email=" + Office.context.mailbox.userProfile.emailAddress)
      .then(function (response) {
        setprioritySenderList(response.data)
        console.log("prioritySenderList", prioritySenderList);
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
  }


  //priority sender code ends

  return (
    <div className="quicksend-container">
      <Header headerChangeScreen={headerChangeScreen} />

      <div className="p-10 compose-bg min-height-100vh">
        {isRestrictedSender === true ? <>
          <div className="quick-dropdown-container">
            <div className="quick-dropdown-content">
              <div className="position-relative">
                <pre><p>Restricted sender or domain, SeAMate can not read content of this email due to security restrictions.</p></pre>
              </div>
            </div>
          </div>

        </> : <>
          <div className="quick-dropdown-container">
            <div key={1} className={`quick-dropdown ${selectedDropdown === 1 ? "quick-open" : ""}`}>
              <div onClick={(e) => handleDropdownClick(1, e)} className="p-2">
                <p className="txt-main-green align-items-center d-flex">
                  {/* <b> */}
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" className="mr-5 fill-theme-color" viewBox="0 -960 960 960"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm120-160h200q17 0 28.5-11.5T560-320q0-17-11.5-28.5T520-360H320q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280Zm0-160h320q17 0 28.5-11.5T680-480q0-17-11.5-28.5T640-520H320q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440Zm0-160h320q17 0 28.5-11.5T680-640q0-17-11.5-28.5T640-680H320q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600Z" /></svg>
                  <span className="heading">Summary</span>

                  {/* </b> */}
                  <span style={{ float: "right" }} className="ms-auto">
                    {selectedDropdown === 1 ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                </p>
              </div>
              {selectedDropdown === 1 && (
                <div className="quick-dropdown-content">
                  {isLoading ? (
                    <div className="spinner-container d-flex justify-content-center align-items-center">
                      <Spinner animation="border" role="status" className="p-10">

                        {!isDetailedSummary ? (<Loading_summary />) : (<Loading_Detailedsummary />)}


                      </Spinner>
                    </div>
                  ) : (
                    <>
                      <div className="position-relative">
                        <pre>{htmlParser.parse(summary)}</pre>
                        {/* <TypingStreaming text={summary}/> */}
                        <div className="d-inline-flex scard feedback feedback-w">
                          <small className="note-text">AI-generated content may contain inaccuracies</small>
                          <i className="v-hr"></i>
                          {!isLikedSummary ? (
                            <BiLike
                              title="like Summary"
                              className="me-2"
                              onClick={
                                !isLikeSummaryDisable
                                  ? () => {
                                    like(
                                      Office.context.mailbox.item.itemId,
                                      "Summary",
                                      Office.context.mailbox.userProfile.emailAddress
                                    );
                                    setLikeSummary(true);
                                    setDislikeSummaryDisable(true);
                                  }
                                  : null
                              }
                            />
                          ) : (
                            <BiSolidLike
                              title="like Summary"
                              className="me-2"
                              onClick={() => {
                                like(
                                  Office.context.mailbox.item.itemId,
                                  "Summary",
                                  Office.context.mailbox.userProfile.emailAddress
                                );
                                // setLikeSummery(!isLikedSummery);
                              }}
                            />
                          )}
                          {!isDisLikedSummary ? (
                            <BiDislike
                              title="Dislike Summary"
                              className="me-2"
                              onClick={
                                !isDislikeSummaryDisable
                                  ? () => {
                                    disLike(
                                      Office.context.mailbox.item.itemId,
                                      "Summary",
                                      Office.context.mailbox.userProfile.emailAddress
                                    );
                                    setDisLikeSummary(true);
                                    setLikeSummaryDisable(true);
                                  }
                                  : null
                              }
                            />
                          ) : (
                            <BiSolidDislike
                              title="Dislike Summary"
                              className="me-2"
                              onClick={() => {
                                disLike(
                                  Office.context.mailbox.item.itemId,
                                  "Summary",
                                  Office.context.mailbox.userProfile.emailAddress
                                );
                              }}
                            />
                          )}

                          <BiCommentEdit onClick={handleCommentIconClickSummary} title="Feedback" />
                        </div>
                      </div>
                      <CSSTransition in={isCommentBoxSummary} timeout={300} classNames="comment-box" unmountOnExit>
                        <div className="openp comments">
                          <div className="rel-pnl">
                            <textarea
                              className="textarea"
                              name="summaryCommenttext"
                              id="summaryCommenttext"
                              placeholder="Enter Comment here"
                              onChange={(e) => setCommentSummary(e.target.value)}
                            ></textarea>
                            <div className="row g-1">
                              <div className="col">
                                <button
                                  className="btn btn-sm nbtn-default-outline w-100 text-center"
                                  id="btnSummaryCommentcancel"
                                  name="btnSummaryCommentcancel"
                                  onClick={handleCommentCancel}
                                >
                                  Cancel
                                </button>
                              </div>

                              <div className="col">
                                <button
                                  className="btn btn-sm nbtn-default w-100 text-center"
                                  id="btnSummaryCommentsubmit"
                                  name="btnSummaryCommentsubmit"
                                  onClick={handlePostCommentSummary}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CSSTransition>
                    </>
                  )}
                  <div className="dropdown-content-btn d-flex align-items-center">
                    <div className="d-inline-flex align-items-center ">
                      {/* <IoCopyOutline title="Copy Summary" className="wh-20 svg-stroke" onClick={handleCopysummary} disabled={disableButtons} /> */}
                      {/* <MdRefresh className="wh-20 svg-fill-p" onClick={getSummary} /> */}
                      {!isDetailedSummary ? (<button
                        className="btn nbtn-link btn-sm px-0 text-theme position-relative d-inline-flex"
                        onClick={getDetailedSummaryStream}
                        disabled={disableButtons}
                      >
                        {" "}
                        Detailed Summary
                      </button>) : (<button
                        className="btn nbtn-link btn-sm px-0 text-theme position-relative d-inline-flex"
                        onClick={getSummaryStream}
                        disabled={disableButtons}
                      >
                        {" "}
                        Short Summary
                      </button>)}
                      {/* <button
                      className="btn nbtn-link btn-sm px-0 text-theme position-relative d-inline-flex"
                      onClick={getDetailedSummaryStream}
                      disabled={disableButtons}
                    >
                      {" "}
                      Detailed Summary
                    </button> */}
                    </div>

                    <div className="ddl-container">
                      {/* <IoLanguage className="ddl-icon" /> */}
                      <select
                        className="dropdown"
                        id="summaryLanguageDropdown"
                        name="summaryLanguageDropdown"
                        onChange={handleSummaryLanguageDropdownValueChange}
                        value={localStorage.getItem("summaryLanguage") ?? "en-US"}
                        disabled={disableButtons}
                      >
                        <option value="en-US">English</option>
                        <option value="ja-JP">Japanese</option>
                        <option value="da-DK">Danish</option>
                        <option value="yue-CN">Cantonese</option>
                        <option value="zh-CN">Chinese</option>
                        <option value="nb-NO">Norwegian</option>
                        <option value="ms-MY">Bahasa Malaysia</option>
                        <option value="id-ID">Bahasa Indonesia</option>
                        <option value="th-TH">Thai</option>
                        <option value="vi-VN">Vietnamese</option>
                        <option value="fil-PH">Tagalog</option>
                        <option value="ko-KR">Korean</option>
                        <option value="ar-AE">Arabic</option>
                        <option value="el-GR">Greek</option>
                        <option value="de-DE">German</option>
                        <option value="es-ES">Spanish</option>
                        <option value="fr-FR">French</option>
                        <option value="it-IT">Italian</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* quickreply starts here */}
            {quickreply.length > 0 ? (
              <div className="scard">
                <div className="container-fluid w-100">
                  <div className="row g-2 align-items-center" style={{ paddingBottom: 8, paddingTop: 4 }}>
                    <div className="col">
                      <p className="txt-main-green align-items-center d-flex my-1 ">
                        {/* <TbHandClick style={{ width: 16, height: 16, marginRight: 4, strokeWidth: 1.2 }} /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" className="mr-5 fill-theme-color" viewBox="0 -960 960 960"><path d="M419-80q-28 0-52.5-12T325-126L124-381q-8-9-7-21.5t9-20.5q20-21 48-25t52 11l74 45v-328q0-17 11.5-28.5T340-760q17 0 29 11.5t12 28.5v200h299q50 0 85 35t35 85v160q0 66-47 113T640-80H419Zm60-520q-17 0-28.5-11.5T439-640q0-2 5-20 8-14 12-28.5t4-31.5q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 17 4 31.5t12 28.5q3 5 4 10t1 10q0 17-11 28.5T202-600q-11 0-20.5-6T167-621q-13-22-20-47t-7-52q0-83 58.5-141.5T340-920q83 0 141.5 58.5T540-720q0 27-7 52t-20 47q-5 9-14 15t-20 6Z" /></svg>
                        <span className="heading">Suggested Replies</span>
                      </p>
                    </div>
                  </div>
                  <div className="container px-1">
                    <div className="row g-1 selectionGroup">
                      {quickreply.map((qr, i) => (
                        // <ObjectRow obj={quickreply}  />
                        <>
                          <input
                            type="radio"
                            disabled={disableqreplyButtons}
                            className="selectionRdo"
                            name="btnradio"
                            id={"btnradio" + i}
                            autoComplete="off"
                            key={qr}
                            onChange={() => {
                              quickReplyButton(qr);
                            }}
                          />
                          <label
                            className="btn nbtn-light text-start w-100 bg-lt p-2 onelinetext"
                            htmlFor={"btnradio" + i}
                            data-title={qr}
                          >
                            <span className="max-line-2"> {qr}</span>{" "}
                          </label>
                        </>
                      ))}
                      <button
                        className="btn btn-sm nbtn-default w-100 text-center"
                        onClick={handleQuickReplyBox}
                        disabled={disableButtons}
                      >
                        {" "}
                        Custom Reply{" "}
                      </button>
                      <CSSTransition in={isCostomQuickDraft} timeout={300} classNames="comment-box" unmountOnExit>
                        <div className="openp comments mb-0">
                          <div className="rel-pnl px-0">
                            <textarea
                              className="textarea"
                              name="cusotmeReplyText"
                              id="cusotmeReplyText"
                              ref={ref}
                              placeholder="Enter Comment here"
                            ></textarea>
                            <p className="quickrepyvalidationtext">{customreplyvalidation}</p>
                            <div className="row g-1">
                              <div className="col">
                                <button
                                  className="btn btn-sm nbtn-default w-100 text-center"
                                  id="btnCusotmeReplyCancel"
                                  name="btnCusotmeReplyCancel"
                                  onClick={handleCustomReplyCancel}
                                >
                                  Cancel
                                </button>
                              </div>

                              <div className="col">
                                <button
                                  disabled={disableButtons}
                                  className="btn btn-sm nbtn-default w-100 text-center"
                                  id="btnCusotmeReplySend"
                                  name="btnCusotmeReplySend"
                                  onClick={handleCustomReplyClick}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CSSTransition>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {/* quickreply ends here */}
            <div key={2} className={`quick-dropdown ${selectedDropdown === 2 ? "quick-open" : ""}`}>
              <div onClick={(e) => handleDropdownClick(2, e)} className="p-10">
                <p className="txt-main-green  align-items-center d-flex">
                  {/* <b> */}
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" className="mr-5 fill-theme-color" viewBox="0 0 24 24"><g><rect fill="none" height="24" width="24" /></g><g><g><path d="M15,16l-4,4h8c1.1,0,2-0.9,2-2v0c0-1.1-0.9-2-2-2H15z" /><path d="M12.06,7.19l-8.77,8.77C3.11,16.14,3,16.4,3,16.66V19c0,0.55,0.45,1,1,1h2.34c0.27,0,0.52-0.11,0.71-0.29l8.77-8.77 L12.06,7.19z" /><path d="M18.71,8.04c0.39-0.39,0.39-1.02,0-1.41l-2.34-2.34c-0.39-0.39-1.02-0.39-1.41,0l-1.83,1.83l3.75,3.75L18.71,8.04z" /></g></g></svg>
                  <span className="heading">Draft</span>

                  {/* </b> */}
                  <span style={{ float: "right" }} className="ms-auto">
                    {selectedDropdown === 2 ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                </p>
              </div>

              {selectedDropdown === 2 && (
                <div className="quick-dropdown-content">
                  {isLoadingdraft ? (
                    <div className="spinner-container d-flex justify-content-center align-items-center">
                      <Spinner animation="border" role="status" className="p-10">
                        {/* <Loading /> */}
                        <Loading_Draft />
                      </Spinner>
                    </div>
                  ) : (
                    <>
                      <div className="position-relative">
                        <pre>{draft}</pre>
                        {/* <TypingStreaming text={draft}/> */}
                        <div className="d-inline-flex scard feedback feedback-w">
                          <small className="note-text">AI-generated content may contain inaccuracies</small>
                          <i className="v-hr"></i>
                          {!isLikedDraft ? (
                            <BiLike
                              title="Like Draft"
                              className="me-2"
                              onClick={
                                !isLikeDraftDisable
                                  ? () => {
                                    like(
                                      Office.context.mailbox.item.itemId,
                                      "Draft",
                                      Office.context.mailbox.userProfile.emailAddress
                                    );
                                    setLikeDraft(true);
                                    setDislikeDraftDisable(true);
                                  }
                                  : null
                              }
                            />
                          ) : (
                            <BiSolidLike
                              title="Like Draft"
                              className="me-2"
                              onClick={() => {
                                like(
                                  Office.context.mailbox.item.itemId,
                                  "Draft",
                                  Office.context.mailbox.userProfile.emailAddress
                                );
                                // setLikeSummery(!isLikedSummery);
                              }}
                            />
                          )}
                          {!isDisLikedDraft ? (
                            <BiDislike
                              title="Dislike draft"
                              className="me-2"
                              onClick={
                                !isDislikeDraftDisable
                                  ? () => {
                                    disLike(
                                      Office.context.mailbox.item.itemId,
                                      "Draft",
                                      Office.context.mailbox.userProfile.emailAddress
                                    );
                                    setDisLikeDraft(true);
                                    setLikeDraftDisable(true);
                                  }
                                  : null
                              }
                            />
                          ) : (
                            <BiSolidDislike
                              title="Dislike draft"
                              className="me-2"
                              onClick={() => {
                                disLike(
                                  Office.context.mailbox.item.itemId,
                                  "Draft",
                                  Office.context.mailbox.userProfile.emailAddress
                                );
                              }}
                            />
                          )}

                          <BiCommentEdit onClick={handleCommentIconClickDraft} title="Draft Feedback" />
                        </div>
                      </div>

                      <CSSTransition in={isCommentBoxDraft} timeout={300} classNames="comment-box" unmountOnExit>
                        <div className="openp comments">
                          <div className="rel-pnl">
                            <textarea
                              className="textarea"
                              name="summaryCommenttext"
                              id="summaryCommenttext"
                              ref={ref1}
                              placeholder="Enter Comment here"
                            ></textarea>
                            <div className="row g-1">
                              <div className="col">
                                <button
                                  className="btn btn-sm nbtn-default-outline w-100 text-center"
                                  id="btnSummaryComment"
                                  name="btnSummaryComment"
                                  onClick={handleCommentCancel}
                                >
                                  Cancel
                                </button>
                              </div>

                              <div className="col">
                                <button
                                  className="btn btn-sm nbtn-default w-100 text-center"
                                  id="btnDraftComment"
                                  name="btnDraftComment"
                                  onClick={handlePostCommentDraft}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CSSTransition>
                      {/* )} */}
                    </>
                  )}
                  <div className="dropdown-content-btn  d-flex align-items-center">
                    <div className="d-inline-flex" disabled={disableqreplyButtons}>
                      {/* <IoCopyOutline className="wh-20 svg-stroke" onClick={handleCopydraft()} title="Copy Draft" disabled={disableButtons} /> */}

                      {disableqreplyButtons ? (
                        <>
                        </>) : (<>
                          <MdRefresh
                            className="wh-20 svg-fill-p"
                            disabled={disableqreplyButtons}
                            onClick={getDraftStream}
                            title="Regenerate Draft"

                          />
                          <PiMicrosoftOutlookLogoFill
                            disabled={disableqreplyButtons}
                            onClick={copytoOutlook}
                            className="wh-20 svg-fill"
                            title="Copy to Outlook"

                          />
                        </>)}

                    </div>

                    <div className="ddl-container">
                      {/* <IoLanguage className="ddl-icon" /> */}
                      <select
                        disabled={disableqreplyButtons}
                        className="dropdown"
                        id="draftLanguageDropdown"
                        name="draftLanguageDropdown"
                        onChange={handleDraftLanguageDropdownValueChange}
                        value={localStorage.getItem("draftLanguage") ?? "en-US"}
                      >
                        <option value="en-US">English</option>
                        <option value="ja-JP">Japanese</option>
                        <option value="da-DK">Danish</option>
                        <option value="yue-CN">Cantonese</option>
                        <option value="zh-CN">Chinese</option>
                        <option value="nb-NO">Norwegian</option>
                        <option value="ms-MY">Bahasa Malaysia</option>
                        <option value="id-ID">Bahasa Indonesia</option>
                        <option value="th-TH">Thai</option>
                        <option value="vi-VN">Vietnamese</option>
                        <option value="fil-PH">Tagalog</option>
                        <option value="ko-KR">Korean</option>
                        <option value="ar-AE">Arabic</option>
                        <option value="el-GR">Greek</option>
                        <option value="de-DE">German</option>
                        <option value="es-ES">Spanish</option>
                        <option value="fr-FR">French</option>
                        <option value="it-IT">Italian</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div key={3} className={`quick-dropdown ${selectedDropdown === 3 ? "quick-open" : ""}`}>
              <div onClick={(e) => handleDropdownClick(3, e)} className="p-10">
                <p className="txt-main-green  align-items-center d-flex">
                  {/* <b> */}
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" className="mr-5 fill-theme-color" viewBox="0 0 24 24"><g><rect fill="none" height="24" width="24" /></g><g><g><g><g><path d="M21,10V4c0-1.1-0.9-2-2-2H3C1.9,2,1.01,2.9,1.01,4L1,16c0,1.1,0.9,2,2,2h11v-5c0-1.66,1.34-3,3-3H21z M11.53,10.67 c-0.32,0.2-0.74,0.2-1.06,0L3.4,6.25C3.15,6.09,3,5.82,3,5.53c0-0.67,0.73-1.07,1.3-0.72L11,9l6.7-4.19 C18.27,4.46,19,4.86,19,5.53c0,0.29-0.15,0.56-0.4,0.72L11.53,10.67z" /><path d="M22,14c-0.55,0-1,0.45-1,1v3c0,1.1-0.9,2-2,2s-2-0.9-2-2v-4.5c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5V17 c0,0.55,0.45,1,1,1s1-0.45,1-1v-3.5c0-1.38-1.12-2.5-2.5-2.5S15,12.12,15,13.5V18c0,2.21,1.79,4,4,4s4-1.79,4-4v-3 C23,14.45,22.55,14,22,14z" /></g></g></g></g></svg>
                  <span className="heading">Attachments</span>

                  {/* </b> */}
                  <span style={{ float: "right" }} className="ms-auto">
                    {selectedDropdown === 3 ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                </p>
              </div>

              {selectedDropdown === 3 && (
                <div className="quick-dropdown-content">
                  {Office.context.mailbox.item.attachments.length > 0 ? (
                    <>
                      <div className="p-2">
                        <div>
                          <select
                            className="input-control"
                            name="attachmentdropdown"
                            id="attachmentdropdown"
                            onChange={handleattachmentDropdownValueChange}
                          >
                            {Office.context.mailbox.item.attachments.map((e, key) => {
                              return (
                                <option key={key} value={key}>
                                  {e.name.length > 40
                                    ? e.name.substring(0, 30) + "...." + e.name.substring(e.name.length - 10)
                                    : e.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div>
                          <button
                            title="Summarise Selected Attachment"
                            className="btn nbtn-default text-start w-100 text-center btn-sm"
                            onClick={() => {
                              getattachmentSummaryContent(attachmentDropdownValue);
                              setGenratekeAttachmentSummary(true);
                            }}
                          >
                            Summarise
                          </button>
                        </div>
                      </div>
                      {isLoadingattachmentSummary ? (
                        <div className="spinner-container d-flex justify-content-center align-items-center">
                          <Spinner animation="border" role="status" className="p-10">
                            {/* <Loading /> */}
                            <Loading_Attachment />
                          </Spinner>
                        </div>
                      ) : (
                        <>
                          {isGenrateAttachmentSummary ? (
                            <>
                              <div className="position-relative">
                                <pre className="attachmentwrap">{attachmentSummary}</pre>
                                <div className="d-inline-flex scard feedback feedback-w">
                                  <small className="note-text">AI-generated content may contain inaccuracies</small>
                                  <i className="v-hr"></i>
                                  {!isLikedAttachmentSummary ? (
                                    <BiLike
                                      title="Like Summary"
                                      className="me-2"
                                      onClick={
                                        !isLikeAttachmentSummaryDisable
                                          ? () => {
                                            like(
                                              Office.context.mailbox.item.itemId,
                                              "AttachmentSummary",
                                              Office.context.mailbox.userProfile.emailAddress
                                            );
                                            setLikeAttachmentSummary(true);
                                            setDislikeAttachmentSummaryDisable(true);
                                          }
                                          : null
                                      }
                                    />
                                  ) : (
                                    <BiSolidLike
                                      title="Like Summary"
                                      className="me-2"
                                      onClick={() => {
                                        like(
                                          Office.context.mailbox.item.itemId,
                                          "AttachmentSummary",
                                          Office.context.mailbox.userProfile.emailAddress
                                        );
                                        // setLikeSummery(!isLikedSummery);
                                      }}
                                    />
                                  )}
                                  {!isDisLikedAttachmentSummary ? (
                                    <BiDislike
                                      title="Dislike Summary"
                                      className="me-2"
                                      onClick={
                                        !isDislikeAttachmentSummaryDisable
                                          ? () => {
                                            disLike(
                                              Office.context.mailbox.item.itemId,
                                              "AttachmentSummary",
                                              Office.context.mailbox.userProfile.emailAddress
                                            );
                                            setDisLikeAttachmentSummary(true);
                                            setLikeAttachmentSummaryDisable(true);
                                          }
                                          : null
                                      }
                                    />
                                  ) : (
                                    <BiSolidDislike
                                      title="Dislike Summary"
                                      className="me-2"
                                      onClick={() => {
                                        disLike(
                                          Office.context.mailbox.item.itemId,
                                          "AttachmentSummary",
                                          Office.context.mailbox.userProfile.emailAddress
                                        );
                                      }}
                                    />
                                  )}

                                  <BiCommentEdit onClick={handleCommentIconClickAttachment} title="Feedback" />
                                </div>
                              </div>
                              <CSSTransition
                                in={isCommentBoxAttachment}
                                timeout={300}
                                classNames="comment-box"
                                unmountOnExit
                              >
                                <div className="openp comments">
                                  <div className="rel-pnl">
                                    <textarea
                                      className="textarea"
                                      name="attachmentCommenttext"
                                      id="attachmentCommenttext"
                                      placeholder="Enter Comment here"
                                      onChange={(e) => setCommentAttachment(e.target.value)}
                                    ></textarea>
                                    <div className="row g-1">
                                      <div className="col">
                                        <button
                                          className="btn btn-sm nbtn-default-outline w-100 text-center"
                                          id="btnAttachmentComment"
                                          name="btnAttachmentComment"
                                          onClick={handleCommentCancel}
                                        >
                                          Cancel
                                        </button>
                                      </div>

                                      <div className="col">
                                        <button
                                          className="btn btn-sm nbtn-default w-100 text-center"
                                          id="btnDraftComment"
                                          name="btnDraftComment"
                                          onClick={handlePostCommentAttachment}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </CSSTransition>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <pre>No attachment in this Email</pre>
                  )}

                  {/* <pre>{AttachmentList[0].name}</pre> */}

                  <div className="dropdown-content-btn d-flex align-items-center ">
                    <div className="d-inline-flex">
                      {/* {isGenrateAttachmentSummary ? (
                    <IoCopyOutline className="wh-20 svg-stroke" />):(<></>)} */}
                      {/* <MdRefresh className="wh-20 svg-fill-p" /> */}
                    </div>
                    <div className="ddl-container">
                      {/* <IoLanguage className="ddl-icon" /> */}
                      <select
                        disabled={disableButtons}
                        className="dropdown"
                        id="attachmentLanguageDropdown"
                        name="attachmentLanguageDropdown"
                        onChange={handleattachmentSummarylanguageDropdownValueChange}
                        value={localStorage.getItem("attachmentsummaryLanguage") ?? "en-US"}
                      >
                        <option value="en-US">English</option>
                        <option value="ja-JP">Japanese</option>
                        <option value="da-DK">Danish</option>
                        <option value="yue-CN">Cantonese</option>
                        <option value="zh-CN">Chinese</option>
                        <option value="nb-NO">Norwegian</option>
                        <option value="ms-MY">Bahasa Malaysia</option>
                        <option value="id-ID">Bahasa Indonesia</option>
                        <option value="th-TH">Thai</option>
                        <option value="vi-VN">Vietnamese</option>
                        <option value="fil-PH">Tagalog</option>
                        <option value="ko-KR">Korean</option>
                        <option value="ar-AE">Arabic</option>
                        <option value="el-GR">Greek</option>
                        <option value="de-DE">German</option>
                        <option value="es-ES">Spanish</option>
                        <option value="fr-FR">French</option>
                        <option value="it-IT">Italian</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div key={4} className={`quick-dropdown ${selectedDropdown === 4 ? "quick-open" : ""}`}>
              <div onClick={(e) => handleDropdownClick(4, e)} className="p-10">
                <p className="txt-main-green  align-items-center d-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" className="mr-5 fill-theme-color" viewBox="0 0 24 24" ><g fill="none"><path d="M0 0h24v24H0V0z" /><path d="M0 0h24v24H0V0z" opacity=".87" /></g><path d="M11 7h2v2h-2zm1 10c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm0-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg>
                  <span className="heading">Other Information </span>


                  <span style={{ float: "right" }} className="ms-auto">
                    {selectedDropdown === 4 ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                </p>
              </div>

              {selectedDropdown === 4 && (
                <div className="quick-dropdown-content p-1">

                  <div className="px-2">
                    <div className="mb-2">
                      <span className="heading d-block mb-1">Key People </span>
                      <label className="btn nbtn-light text-start w-100 bg-lt p-2 container" >
                        <span className="row align-items-center w-100 mx-0 g-0">
                          <span className="col lh-1">
                            <span className="d-block"> <b>{Office.context.mailbox.item.from.displayName}</b><br />
                              <small className="text-theme">{Office.context.mailbox.item.from.emailAddress}</small>
                            </span>
                          </span>
                          <span className="col-auto">
                            {!isPrioritySender ? (<HiOutlineStar onClick={addPrioritySender} />) : (<HiStar className="wh-20 svg-fill" onClick={removePrioritySender} />)}

                          </span>
                        </span>
                      </label>
                    </div>
                    {/* <div>
            <span className="heading">Citations </span>
            <div>

          <div className="text-start w-100 p-1 container mb-1" >
                          <div className="row align-items-center w-100 mx-0">
                              <div className="col-auto">
                                  <div className="thumb-1"><img sourse={Webimg}/></div>
                              </div>
                            <div className="col lh-1">
                          <small className="text-theme">www.seamateai.com</small><br/>
                            <span className="max-line-2"> lorem ipsum sample text paragraph title text sample paragraph title text sample
                              </span>
                              </div>
                          </div>
                        </div>
          <div className="text-start w-100 p-1 container mb-1" >
                          <div className="row align-items-center w-100 mx-0">
                              <div className="col-auto">
                                  <div className="thumb-1"><img sourse={Webimg}/></div>
                              </div>
                            <div className="col lh-1">
                          <small className="text-theme">www.seamateai.com</small><br/>
                            <span className="max-line-2"> lorem ipsum sample text paragraph title text sample paragraph title text sample
                              </span>
                              </div>
                          </div>
                        </div>
          <div className="text-start w-100 p-1 container mb-1" >
                          <div className="row align-items-center w-100 mx-0">
                              <div className="col-auto">
                                  <div className="thumb-1"><img sourse={Webimg}/></div>
                              </div>
                            <div className="col lh-1">
                          <small className="text-theme">www.seamateai.com</small><br/>
                            <span className="max-line-2"> lorem ipsum sample text paragraph title text sample paragraph title text sample
                              </span>
                              </div>
                          </div>
                        </div>
            </div>
          </div> */}

                  </div>


                </div>
              )}
            </div>
          </div>
        </>}

      </div>
    </div>
  );
}
