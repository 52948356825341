// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("./taskpane.css", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../App.css", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../assets/new-logo-animated.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = " <!doctype html> <html lang=\"en\" data-framework=\"javascript\"> <head> <meta charset=\"UTF-8\"/> <meta http-equiv=\"X-UA-Compatible\" content=\"IE=Edge\"/> <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\"> <title>SeAMate Task Pane Add-in</title> <" + "script src=\"https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js\"><" + "/script> <link rel=\"stylesheet\" href=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> <link rel=\"stylesheet\" href=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> </head> <body style=\"width:100%;height:100%;margin:0;padding:0\"> <div class=\"loadingwrap\"> <div><img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"Initializing...\" style=\"width:64px\"/> </div> </div> <div id=\"container\"> </div> <div id=\"tridentmessage\" style=\"display:none;padding:10\"> This add-in will not run in your version of Office. Please upgrade either to perpetual Office 2021 (or later) or to a Microsoft 365 account. </div> <" + "script>if(-1!==navigator.userAgent.indexOf(\"Trident\")||-1!==navigator.userAgent.indexOf(\"Edge\")){var tridentMessage=document.getElementById(\"tridentmessage\"),normalContainer=document.getElementById(\"container\");tridentMessage.style.display=\"block\",normalContainer.style.display=\"none\"}<" + "/script> </body> </html> ";
// Exports
export default code;